<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div class="verify-old">
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">验证码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="old-mcode"
                    type="text"
                    maxlength="6"
                    placeholder="请输入验证码"
                    value=""
                  />
                </p>
              </div>
              <div class="weui-cell__ft">
                <button class="weui-vcode-btn btn-verify public-btn-vcode">
                  身份验证
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="public-area-submit-box">
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__bd">
                <a
                  href="javascript:;"
                  class="weui-btn weui-btn_primary public-btn-user-edit-next"
                  >下一步</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="set-new hidden">
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">Email</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="email"
                    type="text"
                    maxlength="32"
                    placeholder="请输入Email"
                    value=""
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">验证码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="email-vcode"
                    type="text"
                    maxlength="6"
                    placeholder="请输入邮箱验证码"
                    value=""
                  />
                </p>
              </div>
              <div class="weui-cell__ft">
                <button
                  class="weui-vcode-btn btn-verify-new public-btn-vcode"
                  data-field="email"
                >
                  获取验证码
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="public-area-submit-box">
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__bd">
                <a
                  href="javascript:;"
                  class="weui-btn weui-btn_primary btn-save-new"
                  data-field="email"
                  >保存</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'users'"></tabbar>
  </div>
</template>

<script>
import tabbar from '../components/tabbar/index.vue'
import wxauth from '../mixin/wxauth'
export default {
  mixins: [wxauth],
  name: "editEmail",
  components: {
    tabbar
  },
  data () {
    return {
    }
  }
};
</script>

<style lang="css" scoped>
.public-container{
  margin: unset;
}
.weui-btn.public-btn-user-edit-next{
  padding: unset;
}
</style>